<template>
  <div class="about">
    <div class="grid">
      <div>
        <article>
          <header><strong>Your Farmers ({{ farmers.length }})</strong></header>
          <table>
            <thead>
              <tr>
              <th>Name</th>
              <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="farmer in farmers" v-bind:key="farmer.login">
                <td>{{ farmer.login }}</td>
                <td>{{ farmerStatus[farmer.status] }}</td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div>
        <article>
          <header><strong>Add Farmer</strong></header>
          <form v-on:submit="addFarmer">
            <label for="login">
              <input type="text" id="login" name="login" placeholder="LeekWars Login" required>
            </label>
            <label for="password">
              <input type="text" id="password" name="password" placeholder="LeekWars Password" required>
            </label>
            <small>Your login and password won't be crypted, use this service at your own risk.</small>
            <button type="submit">Add account</button>
          </form>
        </article>

      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";
import Vue from 'vue';

export default {
  data() {
    return {
      user: store.getters.getUser,
      farmers: [],
      unsubFarmers: null,
      farmerStatus: [
        "Not Tested",
        "Working",
        "Can't connect"
      ]
    }
  },
  mounted() {
    const q = query(collection(Vue.firebase.db, "LW_FARMERS"), where("uid", "==", this.user.uid))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const farmers = [];
      querySnapshot.forEach((doc) => {
          farmers.push(doc.data());
      });
      this.farmers = farmers;
    });
    this.unsubFarmers = unsubscribe;
  },
  methods: {
    addFarmer(e) {
      e.preventDefault();
      let uid = this.user.uid;
      let login = e.target[0].value;
      let password = e.target[1].value;
      if (login == null || login.length == 0 || password == null || password.length == 0) {
        return;
      }
      addDoc(collection(Vue.firebase.db, "LW_FARMERS"), {
        uid: uid,
        login: login,
        password: password,
        status: 0
      });
    }
  }
}

/**
 * 
 * LW_FARMERS :
 * 
 * UID = getUser.uid (+ Firebase Rule if Possible)
 * login == display
 * password
 * status = ""
 **/

</script>
